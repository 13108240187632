export const environment = {
	production: false,
	keycloak: {
		issuer: 'https://kc.dev.openkapitals.com',
		realm: 'OpenKapital',
		clientId: 'okclient',
	},
	checkLoginInterval: 300,
	hostName: 'https://www.dev.openkapitals.com',
	ok_backend: 'https://api.fo.dev.openkapitals.com',
};
